const API_KEY = process.env.API_KEY || 'just_key';
const API_HOST = process.env.API_HOST || 'http://localhost:3000';
const API_PATH_PREFIX = process.env.API_PATH_PREFIX || '/dev';
const DEBUG = process.env.DEBUG || false;
const IS_SERVER = typeof window === 'undefined'
const SERVICE_HOST = process.env.SERVICE_HOST || 'http://localhost:3001'
const GA_ID = process.env.GA_ID
const CONFIG_BASE_URL = 'https://img.purrmaster.com/greytiger/cat-jump/config'
const REVALIDATE_SECONDS = 180 // 3 minutes
const FB_CLIENT_ID = process.env.FB_CLIENT_ID;
const FB_CLIENT_SECRET = process.env.FB_CLIENT_SECRET;
const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;

const API_PATH = '/api/v1'

export {
  API_KEY,
  API_HOST,
  API_PATH,
  API_PATH_PREFIX,
  CONFIG_BASE_URL,
  DEBUG,
  FB_CLIENT_ID,
  FB_CLIENT_SECRET,
  GA_ID,
  GOOGLE_CLIENT_ID,
  IS_SERVER,
  REVALIDATE_SECONDS,
  SERVICE_HOST,
};
