import React, { useState } from 'react'
import styled from 'styled-components'
import ClickOutside from '../util/ClickOutside'
import { HIGHLIGHT_COLOR, NEGATIVE_HIGHLIGHT_COLOR } from '../../constants/style'
const InputContainerWrapperDefault = styled.div`
  color: #333;
  border-bottom: ${(props) => props.isFocus ? `solid 1px ${props.highlightColor || HIGHLIGHT_COLOR}` : 'solid 1px #999'};
  background-color: ${(props) => props.bgColor || '#eee' };
  display: block;
  width: ${props => props.width || 'inherit'};
`

const InputWrapperDefault = styled.input`
  background-color: ${(props) => props.bgColor || '#eee' };
  border-width: 0px;
  outline: none;
  padding: 0px;
  width: 100%;
`

const FieldWrapperDefault = styled.div`
  display: flex;
  flex-direction: column;

  ${InputContainerWrapperDefault} {
    margin-bottom: 5px
  }
`

const ErrorWrapper = styled.small`
  min-height: 16px;
  color: ${NEGATIVE_HIGHLIGHT_COLOR};
`

const Input = (props) => {
  const [isFocus, setFocus] = useState(false);
  const { bgColor,
    disabled,
    error,
    highlightColor,
    readOnly,
    showErrorField,
    type,
    width,
    max = Infinity,
    min = -Infinity,
  } = props;

  const InputWrapper = props.inputWrapper ? props.inputWrapper : InputWrapperDefault;
  const FieldWrapper = props.FieldWrapper ? props.FieldWrapper : FieldWrapperDefault;
  const InputContainerWrapper = props.inputContainerWrapper ? props.inputContainerWrapper : InputContainerWrapperDefault;
  return (
    <>
      <ClickOutside onClickOutside={() => setFocus(false)}></ClickOutside>
      <FieldWrapper>
        <InputContainerWrapper
          bgColor={bgColor}
          highlightColor={highlightColor}
          isFocus={isFocus}
          width={width}
        >
          <InputWrapper
            autoCapitalize="sentences"
            autoComplete="on"
            autoCorrect="on"
            bgColor={bgColor}
            dir="auto"
            disabled={disabled}
            maxLength="50"
            name="name"
            onClick={() => setFocus(true)}
            placeholder={props.placeholder}
            readOnly={readOnly}
            spellCheck="true"
            type={type || "text"}
            value={props.value}
            {...props}
            onChange={(e)=> {
              if( type === 'number' &&  
                (e.target.value > max || e.target.value < min)
              ) return ;
              props.onChange(e)
            }}
          />
        </InputContainerWrapper>
        {showErrorField && <ErrorWrapper>{error}</ErrorWrapper>}
      </FieldWrapper>
    </>
  );
}

Input.defaultProps = {
  onChange: () => {},
  showErrorField: true,
}

export default Input;
